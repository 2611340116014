import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import EnvContext from '../../context/EnvContext';
import { Text } from '@chakra-ui/react';

const ResponsiveCarrousel = ({ courseList }) => {
  const { backendUrl } = useContext(EnvContext);
  return (
    <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false}>
      {courseList.map(course => (
        <div key={course._id}> {/* Each child in a list should have a unique "key" prop. */}
          <img src={`${backendUrl}${course.banner}`} />
          <p
            style={{
              background: 'black',
              color: 'white',
              position: 'absolute',
              bottom: 0,
              padding: '4px 0px',
            }}
          >
            {course.courseName}
          </p>
        </div>
      ))}
    </Carousel>
  );
};

export default ResponsiveCarrousel;
