import axios from "axios";
import { createContext, useEffect, useState, useContext } from "react";
import toast from "react-hot-toast";
import EnvContext from "./EnvContext";
import { useToast } from "@chakra-ui/react";

const SiteManagerContext = createContext();

export const SiteManagerContextProvider = ({ children }) => {
  const toast = useToast();

  const { backendUrl } = useContext(EnvContext);
  const [data, setData] = useState({});
  const [update, setUpdate] = useState(false);



  const getSiteManager = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {

      await axios
        .get(`${backendUrl}/site_manager`, { headers: headers })
        .then(res => {
          console.log('site_manager', res);
          setData(res.data.siteManager);

        });
    } catch (err) {
      console.error(err)
    }
  };

  const handleAddSiteManager = async postData => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const addSiteManagerPromise = axios.post(`${backendUrl}/site_manager`, postData, { headers: headers });

    toast.promise(addSiteManagerPromise, {
      loading: {
        title: 'Adding Site Manager...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      success: {
        title: 'Site Manager added successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to add Site Manager!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
    });

    try {
      const res = await addSiteManagerPromise;
      toast.success(res.data.message, {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      getSiteManager();
    } catch (err) {
      console.error(err);
    }
  };

  const updateSiteManager = async postData => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    console.log('update site manager api hit', postData);

    const updateSiteManagerPromise = axios.put(
      `${backendUrl}/site_manager?siteManagerId=${postData._id}`,
      postData,
      { headers: headers }
    );

    try {


      toast.promise(updateSiteManagerPromise, {
        success: {
          title: 'Site Manager updated successfully!',
          status: 'success',
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
          duration: 1000,
        },
        error: {
          title: 'Failed to update Site Manager!',
          status: 'error',
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
          duration: 1000,
        },
      });

      const res = await updateSiteManagerPromise;
      console.log('Site Manager updated:', res.data);
      getSiteManager();
      toast.success(res.data.message, {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
    } catch (err) {
      console.error('Error updating Site Manager:', err);

    }
  };


  return (
    <SiteManagerContext.Provider
      value={{ handleAddSiteManager, getSiteManager, data, updateSiteManager, setUpdate, update }}
    >
      {children}
    </SiteManagerContext.Provider>
  )
}

export default SiteManagerContext;

export const useSiteManager = (() => useContext(SiteManagerContext));