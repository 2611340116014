// src/context/AllContextProviders.js

import React from 'react';
import { AuthContextProvider } from './AuthContext';
import { EnvContextProvider } from './EnvContext';
import { SyllabusContextProvider } from './SyllabusContext';
import { MentorContextProvider } from './MentorContext';
import { CourseContextProvider } from './CourseListContext';
import { ContentContextProvider } from './ContentContext';
import { AssignmentContextProvider } from './AssignmentContext';
import { ProjectContextProvider } from './ProjectContext';
import { CartContextProvider } from './CartContext';
import { WishListContextProvider } from './WishListContext';
import { CouponContextProvider } from './CouponContext';
import { SiteManagerContextProvider } from './SiteManagerContext';
import { PaymentContextProvider } from './PaymentContext';

const AllContextProviders = ({ children }) => {
    return (
        <AuthContextProvider>
            <EnvContextProvider>
                <SyllabusContextProvider>
                    <MentorContextProvider>
                        <CourseContextProvider>
                            <ContentContextProvider>
                                <AssignmentContextProvider>
                                    <ProjectContextProvider>
                                        <CartContextProvider>
                                            <WishListContextProvider>
                                                <CouponContextProvider>
                                                    <SiteManagerContextProvider>
                                                        <PaymentContextProvider>
                                                            {children}
                                                        </PaymentContextProvider>
                                                    </SiteManagerContextProvider>
                                                </CouponContextProvider>
                                            </WishListContextProvider>
                                        </CartContextProvider>
                                    </ProjectContextProvider>
                                </AssignmentContextProvider>
                            </ContentContextProvider>
                        </CourseContextProvider>
                    </MentorContextProvider>
                </SyllabusContextProvider>
            </EnvContextProvider>
        </AuthContextProvider>
    );
};

export default AllContextProviders;
