import { Box, Image, Flex } from '@chakra-ui/react'
import React from 'react'
import { css, html, java, js, mongo_db, my_sql, node_js, sql, python } from '../../assets/images'

const ScrollingLogo = () => {
    return (
        <Box
            overflow="hidden"
            style={{ width: '100%' }}
            mt={{
                base: '25px',
                sm: '25px',
                md: '50px',
            }}
            mb={{ base: '25px', md: '25px' }}
        >
            <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                h={{
                    base: '100px',
                    md: '100px',
                    lg: '300px',
                }}
                w={{
                    base: '50vh',
                    md: '50vh',
                    lg: '150vw',
                }}
                gap={5}
                style={{
                    animation: 'color2 20s ease-in-out 2s infinite alternate-reverse',
                }}
            >
                <Image
                    src={sql}
                    boxSize={{
                        base: '100px',
                        md: '100px',
                        lg: '200px',
                    }}
                    marginLeft="0"
                />
                <Image
                    src={python}
                    boxSize={{
                        base: '100px',
                        md: '100px',
                        lg: '200px',
                    }}
                    marginLeft="0"
                />
                <Image
                    src={java}
                    boxSize={{
                        base: '100px',
                        md: '100px',
                        lg: '200px',
                    }}
                    marginLeft="0"
                />
                <Image
                    src={js}
                    boxSize={{
                        base: '100px',
                        md: '100px',
                        lg: '200px',
                    }}
                    marginLeft="0"
                />
                <Image
                    src={html}
                    boxSize={{
                        base: '100px',
                        md: '100px',
                        lg: '200px',
                    }}
                    marginLeft="0"
                />
                <Image
                    src={mongo_db}
                    boxSize={{
                        base: '100px',
                        md: '100px',
                        lg: '200px',
                    }}
                    marginLeft="0"
                />
                <Image
                    src={css}
                    boxSize={{
                        base: '100px',
                        md: '100px',
                        lg: '200px',
                    }}
                    marginLeft="0"
                />
                <Image
                    src={node_js}
                    boxSize={{
                        base: '100px',
                        md: '100px',
                        lg: '200px',
                    }}
                    marginLeft="0"
                />
                <Image
                    src={my_sql}
                    boxSize={{
                        base: '100px',
                        md: '100px',
                        lg: '200px',
                    }}
                    marginLeft="0"
                />
            </Flex>
        </Box>
    )
}

export default ScrollingLogo