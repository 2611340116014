import React, { useState ,useContext} from "react";
import { useSpring, animated } from "react-spring";
import Styles from "../../allcss/Card.module.css"
import EnvContext from "../../context/EnvContext.js"
export const HomeCarouselCard = ({imagen,title, text, wordLimit = 20 }) => {
    const { backendUrl } = useContext(EnvContext);
    const [show, setShown] = useState(false);

    const props3 = useSpring({
        opacity: 1,
        transform: show ? "scale(1.03)" : "scale(1)",
        boxShadow: show
            ? "0 20px 25px rgb(0 0 0 / 25%)"
            : "0 2px 10px rgb(0 0 0 / 8%)"
    });

    const truncateText = (text, wordLimit) => {
        const words = text?.split(' ');
        if (words?.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };

    return (
        <animated.div
            className={Styles.card}
            style={props3}
            onMouseEnter={() => setShown(true)}
            onMouseLeave={() => setShown(false)}
        >
            <img src={`${backendUrl}${imagen}`} alt="" />
            <h2>{title}</h2>
            <p>
                {truncateText(text, wordLimit)}
            </p>
           
        </animated.div>
    )
}
