import { lazy } from 'react';

const WishList = lazy(() => import('../pages/user/authentication/WishList'));

const UserDashboard = lazy(() =>
  import('../pages/user/authentication/user/UserDashboard')
);
const AdminHome = lazy(() => import('../pages/admin/AdminHome'));

const ManageProjects = lazy(() =>
  import('../pages/admin/project/ManageProjects')
);
const ManageSyllabus = lazy(() =>
  import('../pages/admin/syllabus/ManageSyllabus')
);

const CourseHome = lazy(() => import('../pages/admin/course/CourseHome'));

const ManageAssignments = lazy(() =>
  import('../pages/admin/assignment/ManageAssignments')
);

const Home = lazy(() => import('../pages/user/regular/Home'));

const IndiCourse = lazy(() => import('../pages/user/regular/IndiCourse'));

const Course = lazy(() => import('../pages/user/regular/Course'));
const Bio = lazy(() => import('../components/others/Bio'));
const VideoPage = lazy(() =>
  import('../pages/user/authentication/video/VideoPage')
);
const Review = lazy(() => import('../pages/user/authentication/video/Review'));
const SignIn = lazy(() => import('../pages/SignIn'));
const SignUp = lazy(() => import('../pages/user/SignUp'));
const AllMessages = lazy(() => import('../pages/admin/messages/AllMessages'));
const TransactionDetails = lazy(() =>
  import('../pages/admin/transactions/TransactionDetails')
);

const LoadingPage = lazy(() =>
  import('../pages/admin/transactions/TransactionDetails')
);
const SuccessfulPaymentPage = lazy(() =>
  import('../pages/payment/SuccessfulPaymentPage')
);
const PaymentFailedPage = lazy(() => {
  import('../pages/payment/PaymentFailedPage');
});

const AllTransactions = lazy(() =>
  import('../pages/user/authentication/AllTransactions')
);

const ProcessinngPayment = lazy(() =>
  import('../pages/payment/ProcessinngPayment')
);

const HandleSubscription = lazy(() =>
  import('../pages/admin/subscription/HandleSubscription')
);

const HandleCourse = lazy(() => import('../pages/admin/course/HandleCourse'));

const SiteManager = lazy(() =>
  import('../pages/admin/siteManager/SiteManager')
);

const SubscriptionPlans = lazy(() =>
  import('../pages/user/regular/Subscription plan/SubscriptionPlans')
);

const ProtectedRoute = lazy(() => import('./ProtectedRoutes'));

const TermsAndConditions = lazy(() =>
  import('../pages/policies/TermsAndConditions')
);

const PrivacyPolicy = lazy(() => import('../pages/policies/PrivacyPolicy'));

const Cancellation_n_refund = lazy(() =>
  import('../pages/policies/PrivacyPolicy')
);

const AboutUs = lazy(() => import('../pages/AboutUs'));
const SubHeader = lazy(() => import('../components/layouts/SubHeader'));
const Services = lazy(() => import('../pages/Services'));
const ContactUsPage = lazy(() => import('../pages/SupportivePages/ContactUs'));
const Coupon = lazy(() => import('../pages/admin/coupon/Coupon'));
const SubscriptionPlan = lazy(() =>
  import('../pages/admin/subscription/SubscriptionPlan')
);

const SubmittedProject = lazy(() =>
  import('../pages/admin/project/SubmittedProject')
);

const BioDetails = lazy(() =>
  import('../pages/user/authentication/BioDetails')
);

const CourseForm = lazy(() => import('../pages/admin/course/CourseForm'));
const UpdateMentorList = lazy(() =>
  import('../pages/admin/users/UpdateMentorList')
);

const GenerateOTP = lazy(() => import('../pages/GenerateOTP'));
const ValidateOTP = lazy(() => import('../pages/ValidateOTP'));
const ChangePassword = lazy(() => import('../pages/ChangePassword'));
const UpdateCourse = lazy(() => import('../pages/admin/course/UpdateCourse'));

const ShoppingCart = lazy(() => import('../pages/user/ShoppingCart'));

const ManageContent = lazy(() =>
  import('../pages/admin/content/ManageContent')
);

const routesConfig = [
  { path: '/', element: <Home /> },
  { path: '/products', element: <Course /> },
  { path: '/indi_course/:cId', element: <IndiCourse /> },
  { path: '/indi_course/checkout', element: <ShoppingCart /> },
  {
    path: '/terms_n_conditions',
    element: <TermsAndConditions />,
    heading: 'Term & Conditions',
  },
  {
    path: '/privacy_policy',
    element: <PrivacyPolicy />,
    heading: 'Privacy Policy',
  },
  {
    path: '/cancellation_n_refund',
    element: <Cancellation_n_refund />,
    heading: 'Cancellation & Refund',
  },
  {
    path: '/about_us',
    element: <AboutUs />,
    heading: 'Who We Are',
  },
  { path: '/services', element: <Services />, heading: ' Our Services' },
  { path: '/loading_page/:merchantID', element: <LoadingPage /> },
  { path: '/successful_payment_page', element: <SuccessfulPaymentPage /> },
  { path: '/processing_payment_page', element: <ProcessinngPayment /> },
  { path: '/failed_payment_page', element: <PaymentFailedPage /> },
  { path: '/cart', element: <ShoppingCart /> },
  { path: '/wishList', element: <WishList /> },
  { path: '/contact_us', element: <ContactUsPage /> },
  { path: '/login', element: <SignIn /> },
  { path: '/sign_up', element: <SignUp /> },
  { path: '/generate_otp', element: <GenerateOTP /> },
  { path: '/validate_otp', element: <ValidateOTP /> },
  { path: '/change_password', element: <ChangePassword /> },
  { path: '/subscription_plans', element: <SubscriptionPlans /> },
  { path: '/subscription_plans/checkout', element: <ShoppingCart /> },
  { path: '/renew/checkout', element: <ShoppingCart /> },
  { path: '/dashboard', element: <UserDashboard />, wrapper: ProtectedRoute },
  { path: '/bio', element: <Bio />, wrapper: ProtectedRoute },
  { path: '/bioDetails', element: <BioDetails /> },
  { path: '/all_transactions', element: <AllTransactions /> },
  { path: '/video_page', element: <VideoPage />, wrapper: ProtectedRoute },
  {
    path: '/admin/home',
    element: <AdminHome />,
    heading: 'Admin Home',
    wrapper: ProtectedRoute,
  },
  {
    path: '/admin/add_course',
    element: <CourseForm />,
    heading: 'Add Course',
    wrapper: ProtectedRoute,
  },
  {
    path: '/admin/update_course',
    element: <UpdateCourse />,
    heading: 'Update Course',
    wrapper: ProtectedRoute,
  },
  {
    path: '/admin/courses',
    element: <CourseHome />,
    heading: 'All Courses',
    wrapper: ProtectedRoute,
  },
  {
    path: '/admin/manage_courses',
    element: <HandleCourse />,
    heading: 'Manage Courses',
    wrapper: ProtectedRoute,
  },
  {
    path: '/admin/manage_subscriptions',
    element: <HandleSubscription />,
    heading: 'Manage Subscription',
    wrapper: ProtectedRoute,
  },
  {
    path: '/admin/manage_reviews',
    element: <Review isAdminViewing={true} />,
    wrapper: ProtectedRoute,
    heading: 'Manage Contents',
    extraProps: { requiresCourseData: true },
  },
  {
    path: '/admin/manage_content',
    element: <ManageContent />,
    heading: 'Manage Contents',
    wrapper: ProtectedRoute,
  },
  {
    path: '/admin/manage_projects',
    element: <ManageProjects />,
    heading: 'Manage Projects',
    wrapper: ProtectedRoute,
  },
  {
    path: '/admin/submited_projects',
    element: <SubmittedProject />,
    heading: 'Submitted Projects',
    wrapper: ProtectedRoute,
  },
  {
    path: '/admin/manage_syllabus',
    element: <ManageSyllabus />,
    heading: 'Manage Syllabus',
    wrapper: ProtectedRoute,
  },
  {
    path: '/admin/manage_assignment',
    element: <ManageAssignments />,
    heading: 'Manage Assignments',
    wrapper: ProtectedRoute,
  },
  {
    path: '/admin/update_mentor',
    heading: 'Mentors',
    element: <UpdateMentorList />,
    wrapper: ProtectedRoute,
  },
  {
    path: '/admin/coupon',
    element: <Coupon />,
    heading: 'Coupon',
    wrapper: ProtectedRoute,
  },
  {
    path: '/admin/subscription_plan',
    element: <SubscriptionPlan />,
    heading: 'Subscription Plans',
    wrapper: ProtectedRoute,
  },
  {
    path: '/admin/site_manager',
    element: <SiteManager />,
    heading: 'Site Manager',
    wrapper: ProtectedRoute,
  },
  {
    path: '/admin/all_messages',
    element: <AllMessages />,
    headingz: 'Messages',
    wrapper: ProtectedRoute,
  },
  {
    path: '/admin/transaction_details',
    element: <TransactionDetails />,
    heading: 'Transaction Details',
    wrapper: ProtectedRoute,
  },
];

export default routesConfig;
