import React from 'react'
import { ChakraProvider, Container, VStack } from '@chakra-ui/react'

import d1 from "../../assets/images/d1.png"
import d2 from "../../assets/images/d2.png"
import d3 from "../../assets/images/d3.png"
import d4 from "../../assets/images/d4.png"
import d5 from "../../assets/images/d5.png"
import Card from './Card';
const data = [
    {
      image:d1,
      title: 'Self-Paced Learning',
      points: [
        'Flexibility: Users can learn at their own pace, making it easy to balance their studies with work or other commitments.',
        'Individualized Learning: Each learner can spend more time on difficult topics and move quickly through areas they find easier, enhancing overall understanding and retention.'
      ],
      reverse: false
    },
    {
      image:d2,
      title: 'Comprehensive Course Offerings',
      points: [
        'Structured Learning Paths: Each course is designed with a clear progression, from beginner to advanced levels, ensuring a thorough understanding of the subject.',
        'Practice and Application: Courses include dedicated practice questions and industry-standard projects to apply theoretical knowledge practically.'
      ],
      reverse: true
    },
    {
    image:d3,
      title: 'Certification and Recognition',
      points: [
        'Completion Certificate: Earn a certificate upon completing all video content, validating your commitment and knowledge.',
        'Excellency Certificate: Achieve an excellency certificate by scoring above 80% on practice questions and projects, demonstrating a high level of proficiency to potential employers.'
      ],
      reverse: false
    },
    {
    image:d4,
      title: 'Flexible Purchasing Options',
      points: [
        'Subscription Plans: Users can opt for subscription plans to access multiple courses at a discounted rate, offering greater value and flexibility.',
        'Discounts: Regular discounts and promotional offers make quality education more affordable and accessible.'
      ],
      reverse: true
    },
    {
    image:d5,
      title: 'Industry-Relevant Content',
      points: [
        'Up-to-Date Curriculum: Courses are regularly updated to reflect the latest industry trends and standards, ensuring learners gain relevant and current knowledge.',
        'Expert Instructors: Courses are designed and delivered by industry experts with real-world experience, providing practical insights and expertise.'
      ],
      reverse: false
    }
  ];
const Home_content = () => {
  return (
    <ChakraProvider>
      <Container maxW="container.lg" py={5}>
        <VStack spacing={5}>
          {data.map((item, index) => (
            <Card
              key={index} 
              image={item.image} 
              title={item.title} 
              points={item.points} 
              reverse={item.reverse} 
            />
          ))}
        </VStack>
      </Container>
    </ChakraProvider>

  )
}

export default Home_content