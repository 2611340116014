import { createContext, useEffect, useState, useContext } from 'react';
import EnvContext from './EnvContext';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';

const CartContext = createContext();

export const CartContextProvider = ({ children }) => {
  const toast = useToast();
  const { backendUrl } = useContext(EnvContext);
  const [cartItems, setCartItems] = useState([]);
  const [unAuthCartItems, setUnAuthCartItems] = useState([]);
  const [totalCart, setTotalCart] = useState(0);
  const [userId, setUserID] = useState(null);
  const [adminCart, setAdminCart] = useState([]);

  // for  subscription
  const [subscriptions, setSubscriptions] = useState([]);
  const [aboutSubscription, setAboutScription] = useState({});

  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    if (Array.isArray(cartItems)) {
      const sum = cartItems.reduce((total, item) => total + item.offerPrice, 0);
      setTotalCart(sum);
    } else {
      if (Array.isArray(unAuthCartItems)) {
        const sum = unAuthCartItems.reduce(
          (total, item) => total + item.offerPrice,
          0
        );
        setTotalCart(sum);
      }
    }
  }, [cartItems, unAuthCartItems]);

  useEffect(() => {
    if (!token) setCartItems('');
  }, [token]);

  useEffect(() => {
    if (!token) {
      localStorage.setItem('unAuthCartItems', JSON.stringify(unAuthCartItems));
    }
  }, [unAuthCartItems, token]);

  const handelAddCart = async data => {
    const CourseID = data?.id || data;
    if (token) {
      const addCartPromise = axios.put(
        `${backendUrl}/user/cart/add_item?courseId=${CourseID}`,
        null,
        { headers: headers }
      );

      // Show a loading toast
      const loadingToast = toast({
        title: 'Adding course to cart...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        duration: 1000,
        containerStyle: {
          marginTop: '5rem',
        },
      });

      try {
        const res = await addCartPromise;

        // Close loading toast and show success toast
        toast.close(loadingToast);
        toast({
          title: 'Course added to cart successfully!',
          description: res.data.message,
          status: 'success',
          position: 'top-right',
          duration: 1000,
          containerStyle: {
            marginTop: '5rem',
          },
        });

        getcart();
      } catch (err) {
        // Close loading toast and show error toast
        toast.close(loadingToast);
        toast({
          title: 'Failed to add course to cart!',
          description: err.response?.data?.message || 'Something went wrong',
          status: 'error',
          position: 'top-right',
          duration: 1000,
          containerStyle: {
            marginTop: '5rem',
          },
        });
      }
    } else {
      // Handle unauthenticated user
      const existingItem = Array.isArray(unAuthCartItems)
        ? unAuthCartItems.find(item => item.id === data.id)
        : undefined;
      if (existingItem) {
        alert('Course is already added to the cart!');
      } else {
        setUnAuthCartItems([...unAuthCartItems, data]);
      }
    }
  };

  const handelAddGroupCart = async courseIds => {
    let loadingToast;
    if (token) {
      try {
        loadingToast = toast({
          title: 'Adding items to cart...',
          status: 'info',
          position: 'top-right',
          isClosable: true,
        });
        await axios
          .put(
            `${backendUrl}/user/cart/add_group_items`,
            { courseIds: courseIds },

            { headers: headers }
          )
          .then(res => {
            toast({
              title: res.data.message,
              status: 'success',
              position: 'top-right',
              isClosable: true,
            });
            setUnAuthCartItems('');
            localStorage.removeItem('unAuthCartItems');
            getcart();
          });
      } catch (err) {
        toast({
          title: err.response?.data?.message || 'Something went wrong',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      } finally {
        // Close the loading state
        if (loadingToast) {
          toast.close(loadingToast);
        }
      }
    }
  };

  const getcart = async () => {
    if (token) {
      try {
        let res = await axios.get(`${backendUrl}/user/cart/`, {
          headers: headers,
        });
        setCartItems(res.data.cart);
        setUnAuthCartItems('');
        localStorage.removeItem('unAuthCartItems');
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handeCartlDelete = async id => {
    if (token) {
      // Show a loading toast
      const loadingToast = toast({
        title: 'Removing item from cart...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        duration : 1000, // Stay until manually closed
        containerStyle: {
          marginTop: '5rem',
        },
      });
      try {
        await axios.put(
          `${backendUrl}/user/cart/remove_item?courseId=${id}`,
          null,
          {
            headers: headers,
          }
        );
        // Close the loading toast
        toast.close(loadingToast);
        // Show success toast after removing from cart
        toast({
          title: 'Item removed from cart successfully!',
          status: 'success',
          position: 'top-right',
          duration: 1000,
          containerStyle: {
            marginTop: '5rem',
          },
        });
        getcart();
      } catch (err) {
        // Close the loading toast in case of an error
        toast.close(loadingToast);

        // Show error toast
        toast({
          title: 'Failed to remove item from cart!',
          description: err.response?.data?.message || 'Something went wrong',
          status: 'error',
          position: 'top-right',
          duration: 1000,
          containerStyle: {
            marginTop: '5rem',
          },
        });

        console.log(err);
      }
    } else {
      const updatedCartItems = unAuthCartItems.filter(item => item.id !== id);
      setUnAuthCartItems(updatedCartItems);
      toast({
        title: 'Item removed from cart successfully!',
        status: 'success',
        position: 'top-right',
        duration: 1000,
        containerStyle: {
          marginTop: '5rem',
        },
      });
    }
  };

  const handelAllDelete = async () => {
    let loadingToast;
    if (token) {
      try {
        // Show a loading toast before making the request
        loadingToast = toast({
          title: 'Removing all items from cart...',
          status: 'info',
          position: 'top-right',
          duration : 1000, // Keep it open until manually closed
          containerStyle: {
            marginTop: '5rem',
          },
        });
        await axios.put(`${backendUrl}/user/cart/remove_all`, null, {
          headers: headers,
        });
        getcart();
      } catch (err) {
        // Close the loading toast in case of an error
        if (loadingToast) {
          toast.close(loadingToast);
        }

        // Show error toast if the request fails
        toast({
          title: 'Failed to remove all items from cart!',
          description: err.response?.data?.message || 'Something went wrong',
          status: 'error',
          position: 'top-right',
          isClosable: true,
          duration: 1000,
        });

        console.log(err);
      }
    }
  };

  const adminDeleteCart = async id => {
    try {
      await axios.put(
        `${backendUrl}/user/cart/remove_item?userId=${userId}&courseId=${id}`,
        null,
        {
          headers: headers,
        }
      );
      getAdminCart();
    } catch (err) {
      console.log(err);
    }
  };

  const getAdminCart = async () => {
    if (token) {
      try {
        let res = await axios.get(`${backendUrl}/user/cart?userId=${userId}`, {
          headers: headers,
        });
        setAdminCart(res.data.cart);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const adminAddCart = async data => {
    const addCartPromise = axios.put(
      `${backendUrl}/user/cart/add_item?userId=${userId}&courseId=${data.id}`,
      null,
      { headers: headers }
    );

    toast.promise(addCartPromise, {
      loading: {
        title: 'Adding course to cart...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      success: {
        title: 'Course added to cart successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to add course to cart!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
    });

    try {
      const res = await addCartPromise;
      getAdminCart();
      toast.success(res.data.message, {
        position: 'top-right',
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getAllSubscription = async () => {
    try {
      await axios
        .get(`${backendUrl}/subscription/all`, { headers: headers })
        .then(({ data }) => {
          setSubscriptions(data.data);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const getAboutSubscription = async subscriptionId => {
    try {
      await axios
        .get(`${backendUrl}/subscription?subscriptionId=${subscriptionId}`, {
          headers: headers,
        })
        .then(({ data }) => {
          setAboutScription(data.data);
        })
        .catch(err => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const deleteSubscriptionPlan = async subscriptionId => {
    try {
      await axios.delete(
        `${backendUrl}/subscription?subscriptionId=${subscriptionId}`,
        {
          headers: headers,
        }
      );
      getAllSubscription();
    } catch (err) {
      console.log(err);
    }
  };
  const addCourseSubscription = async (subscriptionId, courseId) => {
    const postData = {
      courseId: courseId,
    };
    try {
      await axios.put(
        `${backendUrl}/subscription/add_course?subscriptionId=${subscriptionId}`,
        postData,
        { headers: headers }
      );
      getAllSubscription();
    } catch (err) {
      console.log(err);
    }
  };
  const removeCourseSubscription = async (subscriptionId, courseId) => {
    const postData = {
      courseId: courseId,
    };
    try {
      await axios.put(
        `${backendUrl}/subscription/remove_course?subscriptionId=${subscriptionId}`,
        postData,
        { headers: headers }
      );
      getAllSubscription();
    } catch (err) {
      console.log(err);
    }
  };

  const ManuallyAddPlan = async (subscriptionPlanId, userId, planDuration) => {
    try {
      await axios.post(
        `${backendUrl}/user/subscription?subscriptionPlanId=${subscriptionPlanId}&userId=${userId}&planDuration=${planDuration}`,
        null,
        { headers: headers }
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <CartContext.Provider
      value={{
        setCartItems,
        handelAddCart,
        getcart,
        setUnAuthCartItems,
        cartItems,
        handeCartlDelete,
        handelAllDelete,
        unAuthCartItems,
        handelAddGroupCart,
        setUserID,
        userId,
        adminDeleteCart,
        adminCart,
        getAdminCart,
        adminAddCart,
        totalCart,
        setSubscriptions,
        subscriptions,
        aboutSubscription,
        setAboutScription,
        getAllSubscription,
        getAboutSubscription,
        deleteSubscriptionPlan,
        addCourseSubscription,
        removeCourseSubscription,
        ManuallyAddPlan,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
