import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

// Context Component
import CartContext from '../context/CartContext';
import AuthContext from '../context/AuthContext';
import CourseContext from '../context/CourseListContext';
import { cryptokey } from '../pages/user/regular/Home';
import CryptoJS from 'crypto-js';
import SkeletonLoader from './SkeletonLoader';
import routesConfig from './RoutesConfig';

// Routes config

// Conponents(LazyLoading)
// const About = lazy(() => import('../pages/user/regular/About'));

const Header = lazy(() => import('../components/layouts/Header'));

const AdminSubHeader = lazy(() =>
  import('../components/layouts/AdminSubHeader')
);

const Footer = lazy(() => import('../components/layouts/Footer'));

export const AllRoutes = () => {
  const location = useLocation();
  const { handleUserData, getAllProducts, userData } = useContext(AuthContext);
  const { courseData, setTopCourses } = useContext(CourseContext);
  const {
    getcart,
    setCartItems,
    cartItems,
    setUnAuthCartItems,
    handeCartlDelete,
  } = React.useContext(CartContext);
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');
  const unAuthItem = localStorage.getItem('unAuthCartItems');
  const [includedCourses, setIncludedCourses] = useState([]);
  useEffect(() => {
    const storedValues = JSON.parse(localStorage.getItem('unAuthCartItems'));
    if (storedValues) {
      setUnAuthCartItems(storedValues);
    }
    const ciphertext = localStorage.getItem('firstFive');
    if (ciphertext) {
      const bytes = CryptoJS.AES.decrypt(ciphertext, cryptokey);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setTopCourses(decryptedData);
    }

    if (token) {
      handleUserData();
      if (!unAuthItem) getcart();
    }

    getAllProducts();
  }, []);

  useEffect(() => {
    if (!cartItems) return;
    // Extract course IDs from userData.courses
    const userCourseIds = userData?.courses?.map(course => course?.course?._id);
    const included = cartItems?.filter(item =>
      userCourseIds?.includes(item._id)
    );
    setIncludedCourses(included);
  }, [userData, cartItems]);

  useEffect(() => {
    if (!includedCourses) return;
    if (includedCourses.length > 0) {
      includedCourses.forEach(course => {
        handeCartlDelete(course._id);
      });
    }
  }, [includedCourses]);

  useEffect(() => {
    // console.log('set items hit');
    if (!token) setCartItems('');
  }, [token]);

  return (
    <>
      {location.pathname !== '/video_page' &&
        location.pathname !== '/login' &&
        location.pathname !== '/sign_up' &&
        location.pathname !== '/dashboard' &&
        location.pathname !== '/loading_page/:merchantID' &&
        location.pathname !== '/successful_payment_page' &&
        location.pathname !== '/failed_payment_page' && (
          <Suspense
            fallback={
              <div>
                <SkeletonLoader />
              </div>
            }
          >
            <Header />
          </Suspense>
        )}

      <Suspense
        fallback={
          <div>
            <SkeletonLoader />
          </div>
        }
      >
        <Routes>
          {routesConfig.map(
            ({ path, element, heading, wrapper: Wrapper, extraProps }) => (
              <Route
                key={path}
                path={path}
                element={
                  <>
                    {heading && <AdminSubHeader heading={heading} />}
                    {extraProps?.requiresCourseData && !courseData ? (
                      <div>
                        <SkeletonLoader />
                      </div>
                    ) : Wrapper ? (
                      <Wrapper
                        element={React.cloneElement(element, {
                          courseData,
                          ...element.props,
                        })}
                      />
                    ) : (
                      React.cloneElement(element, {
                        courseData,
                        ...element.props,
                      })
                    )}
                  </>
                }
              />
            )
          )}
        </Routes>
      </Suspense>

      {role !== 'admin' &&
        location.pathname !== '/video_page' &&
        location.pathname !== '/login' &&
        location.pathname !== '/sign_up' &&
        location.pathname !== '/generate_otp' &&
        location.pathname !== '/validate_otp' &&
        location.pathname !== '/change_password' && (
          <>
            <Suspense
              fallback={
                <div>
                  <SkeletonLoader />
                </div>
              }
            >
              <Footer />
            </Suspense>
          </>
        )}
    </>
  );
};
