import React from 'react';
import * as ReactDOM from 'react-dom/client';
import './Index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider, ColorModeScript, extendTheme } from '@chakra-ui/react';
import AllContextProviders from './context/AllContextProviders';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import DisableDevtool from 'disable-devtool';

// DisableDevtool();
const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
});

// Root setup
const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

// Render app
root.render(
  <BrowserRouter>
    <ToastContainer />
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <ChakraProvider theme={theme}>
      <AllContextProviders>
        <App />
      </AllContextProviders>
    </ChakraProvider>
  </BrowserRouter>
);
