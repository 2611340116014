import {
  Box,
  Flex,
  Heading,
  Text,
  Image,
  useStyleConfig,
} from '@chakra-ui/react';
import React from 'react';
import '../../App.css';
import { BsChatLeftQuoteFill } from 'react-icons/bs';
import {
  dummyImg2,
  dummyImg3,
  userStoryPriya,
  userStorySonu,
  userStorySridhar,
  userStorysurvi,
} from '../../assets/images';

export const UserStories = () => {
  const styles = useStyleConfig('Box', { variant: 'hoverBox' });

  return (
    <>
      <Flex
        w="100%"
        alignContent={'center'}
        justifyContent={'flex-start'}
        p={7}
        flexDirection={'column'}
      >
        <Heading
          as="h2"
          size={{
            base: 'md',
            sm: 'md',
            md: 'lg',
            lg: 'xl',
          }}
          lineHeight={'1.4em'}
          textAlign="center"
          mb={4}
        >
          User Stories
        </Heading>
        <Text
          fontSize={{
            base: 'sm',
            sm: 'sm',
            md: 'md',
            lg: 'md',
          }}
          textAlign="center"
          fontWeight={'medium'}
          color={'gray.600'}
        >
          Get to know our users and learn how they leverage
        </Text>
        <Text
          fontSize={{
            base: 'sm',
            sm: 'sm',
            md: 'md',
            lg: 'md',
          }}
          textAlign="center"
          fontWeight={'medium'}
          color={'gray.600'}
          mb={{
            base: '5',
            sm: '5',
            md: '10',
            lg: '16',
          }}
        >
          Steps Of Learning Process!
        </Text>
        <Flex
          flexDirection={'column'}
          gap={{
            base: '20px',
            sm: '20px',
            md: '40px',
            lg: '50px',
          }}
          alignItems={'center'}
          mb={'5rem'}
        >
          {/* review card */}
          <Box maxWidth={'650px'} position={'relative'}>
            <Flex
              flexDirection={{
                base: 'column',
                sm: 'column',
                md: 'column',
                lg: 'row',
              }}
              alignItems={'center'}
              bg={'white'}
              border={'1px solid #E4E4E4 '}
              borderRadius={'15px'}
              padding={6}
              transition={'transform 0.2s ease-in-out'}
              _hover={{
                ...styles._hover,
                transform: 'scale(1.05)', // Increase scale on hover
              }}
              background="#e3e3ef"
            >
              <Image
                borderRadius="full"
                boxSize="150px"
                src={userStorySridhar}
                alt="Dan Abramov"
              />
              <Flex flexDirection={'column'}>
                <Text ml={5} align={'justify'}>
                  "I've learned new skills and got certifications that have
                  helped my career. The courses are great, and I love that I can
                  learn at my own speed!"
                </Text>
                <Text ml={5} fontWeight={'700'} align="right">
                  {' '}
                  - Sridhar
                </Text>
              </Flex>
            </Flex>
          </Box>

          {/* review card */}
          <Box maxWidth={'650px'} position={'relative'}>
            <Flex
              flexDirection={{
                base: 'column',
                sm: 'column',
                md: 'column',
                lg: 'row',
              }}
              alignItems={'center'}
              bg={'white'}
              border={'1px solid #E4E4E4 '}
              borderRadius={'15px'}
              padding={6}
              transition={'transform 0.2s ease-in-out'}
              _hover={{
                ...styles._hover,
                transform: 'scale(1.05)', // Increase scale on hover
              }}
              background="#e3e3ef"
            >
              <Image
                borderRadius="full"
                boxSize="150px"
                src={userStorysurvi}
                alt="Dan Abramov"
              />
              <Flex flexDirection={'column'}>
                <Text ml={5} align={'justify'}>
                  "This platform has really helped me. I can learn whenever I
                  want, and the courses and projects are top-notch. The
                  certifications I've earned are recognized and have helped me
                  advance in my career."
                </Text>
                <Text ml={5} fontWeight={'700'} align="right">
                  {' '}
                  - Survi
                </Text>
              </Flex>
            </Flex>
          </Box>

          {/* review card */}
          <Box maxWidth={'650px'} position={'relative'}>
            <Flex
              flexDirection={{
                base: 'column',
                sm: 'column',
                md: 'column',
                lg: 'row',
              }}
              alignItems={'center'}
              bg={'white'}
              border={'1px solid #E4E4E4 '}
              borderRadius={'15px'}
              padding={6}
              transition={'transform 0.2s ease-in-out'}
              _hover={{
                ...styles._hover,
                transform: 'scale(1.05)', // Increase scale on hover
              }}
              background="#e3e3ef"
            >
              <Image
                borderRadius="full"
                boxSize="150px"
                src={userStoryPriya}
                alt="Dan Abramov"
              />
              <Flex flexDirection={'column'}>
                <Text ml={5} align={'justify'}>
                  "I've used many online learning platforms, but this one is the
                  best. The courses are detailed, the projects are practical,
                  and the certifications are respected in the industry. I can
                  learn on my own schedule and achieve great results."
                </Text>
                <Text ml={5} fontWeight={'700'} align="right">
                  {' '}
                  - Priyadarshni
                </Text>
              </Flex>
            </Flex>
          </Box>
          {/* review card */}
          <Box maxWidth={'650px'} position={'relative'}>
            <Flex
              flexDirection={{
                base: 'column',
                sm: 'column',
                md: 'column',
                lg: 'row',
              }}
              alignItems={'center'}
              bg={'white'}
              border={'1px solid #E4E4E4 '}
              borderRadius={'15px'}
              padding={6}
              transition={'transform 0.2s ease-in-out'}
              _hover={{
                ...styles._hover,
                transform: 'scale(1.05)', // Increase scale on hover
              }}
              background="#e3e3ef"
            >
              <Image
                borderRadius="full"
                boxSize="150px"
                src={userStorySonu}
                alt="Dan Abramov"
              />
              <Flex flexDirection={'column'}>
                <Text ml={5} align={'justify'}>
                  "I've used many online learning platforms, but this one is the
                  best. The courses are detailed, the projects are practical,
                  and the certifications are respected in the industry. I can
                  learn on my own schedule and achieve great results."
                </Text>
                <Text ml={5} fontWeight={'700'} align="right">
                  {' '}
                  - Sumant
                </Text>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};
