import { useContext, createContext, useEffect, useState } from 'react';
import EnvContext from './EnvContext';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

const CourseContext = createContext();

export const CourseContextProvider = ({ children }) => {
  const toast = useToast();
  const { backendUrl } = useContext(EnvContext);
  const [courses, setCourses] = useState([]);
  const [topCourses, setTopCourses] = useState([]);
  const [courseId, setCourseId] = useState(() =>
    localStorage.getItem('courseId')
  );
 

  
  const [courseData, setCourseData] = useState({});
  const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };

  useEffect(() => {
    if (courseId) {
      localStorage.setItem('courseId', courseId);
    }
  }, [courseId]);

  const fetchData = async () => {
    try {
      const res = await axios.get(`${backendUrl}/course/all`, { headers });
      setCourses(res.data.courses);
    } catch (err) {
      console.error(err);
    }
  };
  const handleCourseDelete = async id => {
    const deleteCoursePromise = axios.delete(`${backendUrl}/course?courseId=${id}`, { headers });
    toast.promise(deleteCoursePromise, {
      loading: {
        title: 'Deleting the course...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      success: {
        title: 'Course deleted successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to delete course!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
    });
    try {
      await deleteCoursePromise;
      fetchData();
    } catch (err) {
      console.error(err);
    }
  };
  const getCourse = async () => {
    try {
      const res = await axios.get(`${backendUrl}/course/specific?courseId=${courseId}`, {
          headers: headers,
        })
        setCourseData(res.data.course);
    } catch (err) {
      console.error(err);
    }
  };
  const getNoAuthCourse = async (cId) => {
    console.log("cid in courserwef",cId)
    setCourseData({});
    try {
      const res = await axios.get(`${backendUrl}/course?courseId=${cId}`, {
          headers: headers,
        })
         setCourseData(res.data.course);
    } catch (err) {
      console.error(err);
    }
  };

  const getComplitionCertificate = async () => {
    const userId = localStorage.getItem('userId');
    const getCertificatePromise = axios.get(
      `${backendUrl}/user/rs/generate_completion_certificate?userId=${userId}&courseId=${courseId}`,
      { headers: headers }
    );

    toast.promise(getCertificatePromise, {
      loading: {
        title: 'Generating your certificate...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      success: {
        title: 'Certificate generated successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to generate certificate!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
    });

    try {
      const res = await getCertificatePromise;
      const pdfUrl = `${backendUrl}${res.data.data.certificateLink}`;
      window.open(pdfUrl, '_blank');
    } catch (err) {
      console.error(err);
    }
  };
  const getExcellenceCertificate = async () => {
    const userId = localStorage.getItem('userId');
    const certificatePromise = axios.get(
      `${backendUrl}/user/rs/generate_excellence_certificate?userId=${userId}&courseId=${courseId}`,
      { headers: headers }
    );
    toast.promise(certificatePromise, {
      loading: {
        title: 'Generating your certificate...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '62px',
        },
        duration: 1000,
      },
      success: {
        title: 'Certificate generated successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '62px',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to generate certificate!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '62px',
        },
        duration: 1000,
      },
    });
    try {
      const res = await certificatePromise;
      const pdfUrl = `${backendUrl}${res.data.data.certificateLink}`;
      window.open(pdfUrl, '_blank');
    } catch (err) {
      console.error(err);
    }
  };


  return (
    <CourseContext.Provider
      value={{
        courseData,
        courses,
        getCourse,
        handleCourseDelete,
        fetchData,
        setCourseId,
        setCourseData,
        courseId,
        getComplitionCertificate,
        getExcellenceCertificate,
        getNoAuthCourse,
        setTopCourses,
        topCourses,
      }}
    >
      {children}
    </CourseContext.Provider>
  );
};

export default CourseContext;

export const useCourse = () => useContext(CourseContext);
