import React from 'react';
import { Box, Flex, Image, Text, VStack } from '@chakra-ui/react';

const Card = ({ image, title, points, reverse }) => {
  return (
    <Box
      bg={reverse ? '' : 'gray.100'}
      p={5}
      borderRadius="md"
      shadow="md"
      position="relative"
      right={reverse ? '' : { base: 0, lg: 50 }}
      left={reverse ? { base: 0, lg: 50 } : ''}
    >
      <Flex
        direction={{
          base: 'column',
          sm: 'column',
          md: 'column',
          lg: reverse ? 'row-reverse' : 'row',
        }}
        align="center"
      >
        <Image
          src={image}
          boxSize="150px"
          objectFit="cover"
          borderRadius="full"
          alt={title}
          mr={reverse ? 0 : 5}
          ml={reverse ? 5 : 0}
        />
        <VStack align="start">
          <Text
            w="100%"
            fontWeight="bold"
            fontSize="xl"
            mb={2}
            align={{
              base: 'center',
              lg: 'left',
            }}
          >
            {title}
          </Text>
          {points.map((point, index) => (
            <Text key={index} style={{ textAlign: 'justify' }}>
              {point}
            </Text>
          ))}
        </VStack>
      </Flex>
    </Box>
  );
};

export default Card;
